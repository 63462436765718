import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import OrdersList from '../../../components/OrdersList';
import { getOrders } from '../../../services/RestaurantManagerPortalService';
import { getDateRanges } from '../../../utils/DateUtils';
import { getDashboardRefreshInterval } from '../../../utils/config';
import { useInfiniteQuery } from '../../../libs/ReactQueryEnhanced/ReactQueryEnhanced';
import { useNewOrderAlert } from '../../../context/NewOrderAlert';
import {
  getNextPageParam,
  getPageResults,
  produceUpdatedProgress,
  useOrderInfiniteItemMutation,
} from '../Dashboard.controller';
import NoOrders from '../components/NoOrders';

const REFRESH_INTERVAL = getDashboardRefreshInterval();

const fetchNewOrders = ({
  queryKey: [, { types, status, startTimestamp, endTimestamp, restaurantId }],
  pageParam,
}) =>
  getOrders({
    restaurantId,
    start: startTimestamp,
    end: endTimestamp,
    page: pageParam,
    types,
    status,
    details: 1,
    exclusive: 1,
  });

function NewOrdersTab({ restaurant, goToOrderDetails }) {
  const restaurantId = restaurant?.id;
  const restaurantTimezone = restaurant?.timezone;
  const dateRange = useMemo(
    () => getDateRanges(restaurantTimezone).today.range,
    [restaurantTimezone]
  );

  const queryKey = [
    'orders',
    {
      types: ['delivery', 'takeout'],
      status: 'received',
      startTimestamp: dateRange.startDate,
      endTimestamp: dateRange.endDate,
      restaurantId,
    },
  ];

  const { updateOrdersList } = useNewOrderAlert();

  const {
    data,
    isLoading,
    isRefetching,
    isSuccess,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(queryKey, fetchNewOrders, {
    getNextPageParam,
    refetchOnWindowFocus: true,
    refetchInterval: REFRESH_INTERVAL,
    onSuccess: (event) => {
      const ordersList = getPageResults(event);
      updateOrdersList(ordersList);
    },
  });
  const ordersList = useMemo(() => getPageResults(data), [data]);

  const fulfillOrderMutation = useOrderInfiniteItemMutation(
    queryKey,
    produceUpdatedProgress
  );

  return (
    <OrdersList
      onActionPress={fulfillOrderMutation.mutateAsync}
      onItemPress={goToOrderDetails}
      data={ordersList}
      refetch={refetch}
      isLoading={isLoading || isRefetching}
      isFirstLoading={!isSuccess}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      emptyComponent={() => <NoOrders title="No new orders" />}
    />
  );
}

NewOrdersTab.propTypes = {
  restaurant: PropTypes.object,
  goToOrderDetails: PropTypes.func,
};

export default NewOrdersTab;
